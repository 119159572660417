import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"

const FloatingComponent = () => {
    const controls = useAnimation()

    useEffect(() => {
        controls.start({
            y: [0, -20, 0],
            transition: {
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
            },
        })
    }, [controls])

    return (
        <motion.div
            animate={controls}
            style={{ width: 100, height: 100, backgroundColor: "blue" }}
        >
            {/* Your component content */}
        </motion.div>
    )
}

export default FloatingComponent
